import React from "react";
import { Dimmer, Loader, Menu } from "semantic-ui-react";
import LogoGeneralitat from "../gencat-nuevo-completo-w.svg";

import { Trans } from "react-i18next";

export default function Header({
  inverted = true,
  content = <Trans i18nKey="loading" />,
}) {
  return (
    <Menu inverted attached="top" stackable style={{ width: "100%" }}>
      <Menu.Item>
        <img alt="gencat" src={LogoGeneralitat} style={{ width: 103 }} />
      </Menu.Item>
    </Menu>
  );
}
