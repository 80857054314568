import React, { useContext, useState, useEffect } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { Fade } from "react-awesome-reveal";

import { Trans, useTranslation } from "react-i18next";

import { getServices } from "../providers/ServiceProvider";
import {getLanguageNavigator} from "../util/languages"

import es from "date-fns/locale/es";
import ca from "date-fns/locale/ca";
import en from "date-fns/locale/en-GB";

import { DateTimeContext } from "../context/DateTimeContext";
import LoadingComponent from "./LoadingComponent";
import { UIContext } from "../context/UIContext";
import { ServiceContext } from "../context/ServiceContext";
import { Button, Header, Segment } from "semantic-ui-react";
import { addMonths } from "date-fns";
import moment, { months } from "moment";

import { getEvents } from "../providers/CalendarProvider";

registerLocale("ca", ca);
registerLocale("es", es);
registerLocale("en", en);


export default function DateSelect(props) {
  const [startDate, setStartDate] = useContext(DateTimeContext);

  const [dateData, setDateData] = useState(startDate);
  const [isLoading, setIsLoading] = useState(true);
  const [dates, setDates] = useState([]);
  const [isError, setIsError] = useState(false);
  const [hasDate, setHasDate] = useState(false);
  // eslint-disable-next-line
  const [serviceValues, setValues] = useContext(ServiceContext);
  const [dayOld, setDayOld] = useState(startDate.getDate());
  const [monthOld, setMonthOld] = useState(startDate.getMonth());
  const [yearOld, setYearOld] = useState(startDate.getFullYear());
  const [day, setDay] = useState(startDate.getDate());
  const [month, setMonth] = useState(startDate.getMonth());
  const [year, setYear] = useState(startDate.getFullYear());

  const getServiceValue = () => {
    const service = window.location.pathname.split("/")[2];
    if(service != null) return service;
    
    return serviceValues.value;
  }

  const getServiceName = async (organization, service) => {
    try {
      const { data } = await getServices(organization)
      const { displayName } = data.filter(services => services.code == service)[0];
      return displayName;
    } catch (err) {
      console.log(err);
    }
  }

  const getServiceText = () => {
    const service = window.location.pathname.split("/")[2];
    if(service == null){
      return (
        <Segment textAlign="left" secondary>
          <strong>
            <Trans i18nKey="app.management" />
          </strong>
          {serviceValues.text}
        </Segment>
      )
    }
  }

  const { category } = {
    category: getServiceValue(),
  };

  const { t } = useTranslation();

  const MONTHS = [
    t("calendar.months.january"),
    t("calendar.months.february"),
    t("calendar.months.march"),
    t("calendar.months.april"),
    t("calendar.months.may"),
    t("calendar.months.june"),
    t("calendar.months.july"),
    t("calendar.months.august"),
    t("calendar.months.september"),
    t("calendar.months.october"),
    t("calendar.months.november"),
    t("calendar.months.december"),
  ];

  const days = [
    t("calendar.days.sunday"),
    t("calendar.days.monday"),
    t("calendar.days.tuesday"),
    t("calendar.days.wednesday"),
    t("calendar.days.thursday"),
    t("calendar.days.friday"),
    t("calendar.days.saturday"),
  ];

  const MONTHSAPI = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];

  const monthAPI = MONTHSAPI[month];

  const locale = {
    localize: {
      day: (n) => days[n],
    },
    formatLong: {},
  };

  useEffect(() => {
    setDay(startDate.getDate());
    setMonth(startDate.getMonth());
    setYear(startDate.getFullYear());
    setDateData(startDate);
  }, [startDate]);

  useEffect(() => {
    if (category && year && monthAPI) {
      const fetchData = async () => {
        try {
          setIsLoading(true);
          const result = await getEvents(year, monthAPI, category);
          if (result && result.data) {
            setDates(result.data);
            setIsLoading(false);
          } else if (
            Math.floor(moment().diff(startDate, "months", true)) > -3
          ) {
            if (monthOld <= month && yearOld <= year) {
              setStartDate(new Date(year, month + 1, 1));
            } else {
              setStartDate(new Date(year, month - 1, 1));
            }
          } else {
            setIsError(true);
            setIsLoading(false);
          }
        } catch (err) {
          setIsError(true);
          setIsLoading(false);
          console.log(err);
        }
      };
      fetchData();
    }
    setDayOld(startDate.getDate());
    setMonthOld(startDate.getMonth());
    setYearOld(startDate.getFullYear());
  }, [category, year, monthAPI]);

  let availableDays = dates.map((day) => day.date);

  availableDays = availableDays.map(function (d) {
    return new Date(d);
  });
  return (
    <>
      {isLoading ? (
        <LoadingComponent />
      ) : (
        <>
          <Fade>
            {isError ? (
              <>
                <p>
                  <Trans i18nKey="calendar.error_availability" />
                </p>
                <Button
                  content={<Trans i18nKey="timer.btn_return" />}
                  negative
                  size="big"
                  className="button"
                  onClick={() => {
                    if (hasDate == true) {
                      setIsError(false);
                      const newDate = moment().toDate();
                      setStartDate(newDate);
                    } else {
                      window.location.reload(false);
                    }
                  }}
                ></Button>
              </>
            ) : (
              <>
                {getServiceText()}
                <Header
                  sub
                  color="teal"
                  content={<Trans i18nKey="app.time_select" />}
                />
                <DatePicker
                  placeholderText="Click para dates"
                  includeDates={availableDays}
                  inline
                  selected={dateData}
                  shouldCloseOnSelect={true}
                  locale={getLanguageNavigator()}
                  registerLocale
                  onChange={(date) => {
                    setDateData(date);
                  }}
                  dateFormat="dd/MM/yyyy"
                  maxDate={addMonths(new Date(), 3)}
                  renderCustomHeader={({ date }) => (
                    <div
                      style={{
                        margin: 0,
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <button
                        style={{
                          marginLeft: "10px",
                          fontSize: "15px",
                          border: "none",
                          outline: "none",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          const newDate = moment(new Date(year, month, 1))
                            .subtract(1, "months")
                            .toDate();
                          setStartDate(newDate);
                          setDateData(newDate);
                          setHasDate(true);
                        }}
                      >
                        {"<"}
                      </button>

                      <div style={{ fontSize: "18px" }}>
                        {MONTHS[month]} {year}
                      </div>

                      <button
                        style={{
                          marginRight: "10px",
                          fontSize: "15px",
                          border: "none",
                          outline: "none",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          const newDate = moment(new Date(year, month, 1))
                            .add(1, "months")
                            .toDate();
                          setStartDate(newDate);
                          setDateData(newDate);
                          setHasDate(true);
                        }}
                      >
                        {">"}
                      </button>
                    </div>
                  )}
                />

                <Button
                  content={t("next")}
                  icon="chevron right"
                  labelPosition="right"
                  negative
                  size="big"
                  style={{ margin: "15px", marginBottom: "80px" }}
                  onClick={() => {
                    setStartDate(dateData);
                    props.onHandleClickNext && props.onHandleClickNext();
                  }}
                ></Button>
              </>
            )}
          </Fade>
        </>
      )}
    </>
  );
}
